<template>
  <div>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        albo_insegnante_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
      </span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="albo_insegnante_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAlboInsegnante"
        @current-change="setCurrentPageAlboInsegnante"
      >
        <template v-slot:cell-stagione="{ row: data }">
          {{ data.stagione }}
        </template>
        <template v-slot:cell-numero_albo="{ row: data }">
          {{ data.numero_albo }}
        </template>
        <template v-slot:cell-cql="{ row: data }"
          ><!--v-for-->
          <div class="tab-long">
            {{ data.cql }}
          </div>
        </template>
        <template v-slot:cell-data_richiesta="{ row: data }"
          ><div class="text-center">
            {{ data.data_richiesta }}
          </div>
        </template>
        <template v-slot:cell-data_approvazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_approvazione }}
          </div>
        </template>
        <template v-slot:cell-richiesta_pagamento="{ row: data }">
          <div class="text-center">
            <i
              :class="
                data.richiesta_pagamento
                  ? 'bi bi-check-lg fw-bold text-success fs-2'
                  : 'bi bi-x-lg fw-bold text-danger'
              "
            ></i>
          </div>
        </template>
        <template v-slot:cell-approvata="{ row: data }">
          <div class="text-center">
            <i
              :class="
                data.approvata
                  ? 'bi bi-check-lg fw-bold text-success fs-2'
                  : 'bi bi-x-lg fw-bold text-danger'
              "
            ></i>
          </div>
        </template>
        <template v-slot:cell-importo="{ row: data }">
          {{ data.importo }}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-albo-insegnante",
  emits: ["getAlboInsegnanteList"],
  components: { Datatable },

  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsAlboInsegnante = (e) => {
      store.commit("setFetchRowsAlboInsegnante", e);
      emit("getAlboInsegnanteList");
    };
    const setCurrentPageAlboInsegnante = (page) => {
      store.commit("setCurrentPageAlboInsegnante", page);
      emit("getAlboInsegnanteList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAlboInsegnante", columnName);
      store.commit("setSortOrderAlboInsegnante", order);
      emit("getAlboInsegnanteList");
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
      },
      {
        name: "Numero Albo",
        key: "numero_albo",
      },
      {
        name: "Qualifica",
        key: "cql",
      },
      {
        name: "Data richiesta",
        key: "data_richiesta",
        align: "center",
      },
      {
        name: "Data approvazione",
        key: "data_approvazione",
        align: "center",
      },
      {
        name: "Richiesta pagamento",
        key: "richiesta_pagamento",
        align: "center",
      },
      {
        name: "Approvata",
        key: "approvata",
        align: "center",
      },
      {
        name: "Importo",
        key: "importo",
      },
    ]);

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      albo_insegnante_list: computed(() =>
        store.getters.getStateFromName("resultsalbo_insegnante_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedalbo_insegnante_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordalbo_insegnante_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusalbo_insegnante_list")
      ),

      currentPage: computed(() => store.getters.currentPageAlboInsegnante),
      rowsToSkip: computed(() => store.getters.rowsToSkipAlboInsegnante),
      fetchRows: computed(() => store.getters.fetchRowsAlboInsegnante),
      sortColumn: computed(() => store.getters.sortColumnAlboInsegnante),
      sortOrder: computed(() => store.getters.sortOrderAlboInsegnante),
      setFetchRowsAlboInsegnante,
      setCurrentPageAlboInsegnante,
      setSortOrderColumn,
      tableHeader,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,
    };
  },
};
</script>

<style scoped></style>
