<template>
  <div>
    <RinnovoAlboInsegnante />
    <TableAlboInsegnante @getAlboInsegnanteList="getAlboInsegnanteList" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RinnovoAlboInsegnante from "@/components/components-fit/SNM/accesso-insegnante/RinnovoAlboInsegnante.vue";
import TableAlboInsegnante from "@/components/components-fit/SNM/accesso-insegnante/TableAlboInsegnante.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "albo-insegnante",
  components: {
    RinnovoAlboInsegnante,
    TableAlboInsegnante,
  },
  setup() {
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipAlboInsegnante);
    const fetchRows = computed(() => store.getters.fetchRowsAlboInsegnante);
    const sortColumn = computed(() => store.getters.sortColumnAlboInsegnante);
    const sortOrder = computed(() => store.getters.sortOrderAlboInsegnante);

    const getAlboInsegnanteList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_persona: id_persona.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.ALBO_INSEGNANTE_LIST,
        itemName: "albo_insegnante_list",
      });
    };
    getAlboInsegnanteList();

    return {
      id_persona,
      getAlboInsegnanteList,
    };
  },
});
</script>
