<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="row pb-2 fw-bold">
          <p>Richiesta rinnovo iscrizione Albo / Elenchi</p>
        </div>
        <div class="row px-3 pb-5">
          <div class="bg-light-danger text-danger rounded text-start p-5">
            <b>ATTENZIONE:</b> dopo aver affettuato la richiesta di rinnovo è
            necessario procedere al pagamento nell'area "Economato Personale"
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 filter">
            <label class="fw-bold text-gray-700">Stagione</label>
            <SelectInput
              :options="stagioniFilter"
              name="stagione"
              :value="anno"
              @changeSelect="setStagione($event)"
            />
          </div>
          <div class="col-sm-3 filter">
            <label class="fw-bold text-gray-700">Tipo albo</label>
            <input
              type="text"
              class="form-control"
              value="Insegnanti"
              readonly
            />
          </div>
          <div class="col-sm-3 filter">
            <label class="fw-bold text-gray-700">Emissione</label>
            <Datepicker
              v-model="data_emissione"
              @update:modelValue="
                $event ? (data_emissione = fromUTCtoISODate($event)) : ''
              "
              placeholder=""
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              readonly
            />
          </div>
          <div class="col-sm-3 filter">
            <div class="text-start pt-5 mt-1">
              <span
                type="button"
                class="badge bg-secondary rounded blueFit"
                @click="rinnova"
              >
                RICHIEDI RINNOVO
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="mx-auto my-auto text-center" v-if="loadingQualifiche">
    <div class="spinner-border text-gray-600" role="status"></div>
  </div>
  <div class="card shadow" v-if="qualificheList">
    <div class="pt-4 pb-4 pe-4 ps-4">
      <div class="row pb-2 fw-bold">
        <p>Qualifiche attualmente in possesso (Numero Albo - Qualifica):</p>
      </div>
      <div
        class="row pb-2"
        v-for="(qualifica, index) in qualificheList"
        :key="index"
      >
        <div class="d-flex col-3">
          <input
            type="text"
            class="form-control"
            aria-label=""
            :value="qualifica.numero_albo ? qualifica.numero_albo : '-'"
            disabled
          />
        </div>
        <div class="d-flex col-9">
          <input
            type="text"
            class="form-control"
            aria-label=""
            :value="qualifica.cql"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInputNoNull.vue";
import { format, fromUTCtoISODate } from "@/composables/formatDate";

import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  addRinnovoAnnualeAlboInsegnante,
  getListaQualifiche,
} from "@/requests/alboInsegnateRequests";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "rinnovo-albo-insegnante",
  components: { Datepicker, SelectInput, Loading },
  setup() {
    const store = useStore();
    const data_emissione = ref(fromUTCtoISODate(new Date()));
    const anno = ref(new Date().getFullYear());
    const loadingQualifiche = ref(false);

    const setStagione = (idStagione) => {
      anno.value = parseInt(idStagione);
    };

    const stagioni = computed(() => store.getters.stagioni);

    const stagioniFilter = ref([]);
    stagioni.value.forEach((element) => {
      if (element.id >= new Date().getFullYear()) {
        stagioniFilter.value.push(element);
      }
    });

    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const isLoading = ref(false);

    const qualificheList = ref(null);

    const getQualifiche = () => {
      loadingQualifiche.value = true;
      getListaQualifiche(id_persona.value).then((res) => {
        loadingQualifiche.value = false;
        qualificheList.value = res.data.results;
      });
    };
    getQualifiche();

    const rinnova = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "procedendo la richiesta di rinnovo verrà inviata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Invia",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await addRinnovoAnnualeAlboInsegnante(
            id_persona.value,
            anno.value,
            data_emissione.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("Richiesta di rinnovo inviata con successo");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    return {
      data_emissione,
      anno,
      id_persona,
      format,
      fromUTCtoISODate,
      setStagione,
      stagioni,
      stagioniFilter,
      rinnova,
      isLoading,
      getQualifiche,
      qualificheList,
      loadingQualifiche,
    };
  },
});
</script>

<style></style>
